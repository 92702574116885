import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Button 
} from '@mui/material';
import { 
  Search as SearchIcon, 
  CheckCircle as CheckCircleIcon, 
  Business as BusinessIcon,
  OpenInNew as OpenInNewIcon
} from '@mui/icons-material';

const ExtensionInfoPage = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom color="primary">
        Glancejobs Linkedin Company Checker
        </Typography>
        
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Simplify Your Job Search as an International Student
          </Typography>
          <Typography variant="body1" paragraph>
            Our Chrome extension enhances your LinkedIn job search experience by instantly identifying 
            companies that support OPT (Optional Practical Training) and are E-Verified.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Key Features
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <SearchIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Instantly identifies OPT-friendly employers" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Highlights E-Verified companies" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <BusinessIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Seamlessly integrates with LinkedIn job listings" />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            How It Works
          </Typography>
          <List>
            {[
              "Install the extension from the Chrome Web Store",
              "Navigate to LinkedIn Jobs",
              "Browse job listings as usual",
              "Notice visual indicators for OPT-friendly and E-Verified employers",
              "Focus your applications on suitable opportunities"
            ].map((step, index) => (
              <ListItem key={index}>
                <ListItemText primary={`${index + 1}. ${step}`} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Why Use GlanceJobs?
          </Typography>
          <List>
            {[
              "Save time by quickly identifying relevant job opportunities",
              "Reduce stress and uncertainty in your job search",
              "Increase your chances of finding OPT-eligible positions",
              "Streamline your application process"
            ].map((reason, index) => (
              <ListItem key={index}>
                <ListItemText primary={`• ${reason}`} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Button 
            variant="contained" 
            color="primary" 
            size="large" 
            endIcon={<OpenInNewIcon />}
            href="https://chromewebstore.google.com/detail/glancejobs-linkedin-compa/mbkklpipajfbnobcfeclhhikmlnfpnhh?authuser=7&hl=en"
            target='newTab'
          >
            Install Extension
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ExtensionInfoPage;